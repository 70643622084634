/* music */
@font-face {
    font-family: 'Noto Music';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/assets/fonts/noto-music-1.woff2) format('woff2');
    unicode-range: U+25CC, U+2669-266F, U+1D000-1D0F5, U+1D100-1D126, U+1D129-1D1EA, U+1D200-1D245;
}

/* latin-ext */
@font-face {
    font-family: 'Noto Music';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/assets/fonts/noto-music-2.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Noto Music';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/assets/fonts/noto-music-3.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.noto-music-regular {
    font-family: "Noto Music", sans-serif;
    font-weight: 400;
    font-style: normal;
}


.data-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.app-electron #root {
    padding: 12px;
}

.result-section {
    flex: 1;
    overflow: auto;
}

.app-electron .hide-in-electron {
    display: none;
}

.app-button-bar {
    padding-left: 10px;
    height: 49px;
    display: flex;
    flex-direction: row;
    align-items: center
}

.app-electron .app-button-bar {
    padding-left: 0;
}

.app-logo {
    background-image: url(/public/assets/logo/metronome-32.png);
    background-size: cover;
    height: 32px;
    width: 32px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
    .app-logo {
        background-image: url(/public/assets/logo/metronome-64.png);
    }
}

.fade-out-background-fast {
    animation: fade-out 100ms;
    background-color: rgba(255, 172, 28, 1);
}

.fade-out-background {
    animation: fade-out 150ms;
    background-color: rgba(255, 172, 28, 1);
}

.fade-out-background-slow {
    animation: fade-out 200ms;
    background-color: rgba(255, 172, 28, 1);
}

@keyframes fade-out {
    0% {
        background-color: rgba(255, 172, 28, 1);
    }
    100% {
        background-color: rgba(255, 172, 28, 0);
    }
}
