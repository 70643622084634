.beat-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    box-shadow: inset 0 0 0 5px darkgoldenrod, inset 0 0 0 20px black;
}


.display-mode-light .beat-circle {
    box-shadow: inset 0 0 0 5px darkgoldenrod, inset 0 0 0 20px white;
}

.beat-type-0 .beat-circle {
    color: black;
    background-color: black;
}

.beat-type-1 .beat-circle {
    color: gold;
    background-color: gold;
}

.beat-circle span {
    font-weight: bold;
    filter: invert(100%);
    -webkit-filter: invert(100%);
}

.beat-type-2 .beat-circle {
    color: darkorange;
    background-color: darkorange;
}

.beat-type-3 .beat-circle {
    color: saddlebrown;
    background-color: saddlebrown;
}

.beat-type-0.active .beat-circle,
.beat-type-1.active .beat-circle,
.beat-type-2.active .beat-circle,
.beat-type-3.active .beat-circle {
    box-shadow: unset;
    color: transparent;
    background-color: darkgoldenrod;
}
